import { AppId, DevelopEnvironment, Environment, XPlatform } from '@foxeet/domain';
import { VERSION } from './version';
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: Environment = {
  app: AppId.IVFORCE,
  appName: 'IV Force',
  wikiURL: 'https://wiki-force.valumre.com/',
  platform: XPlatform.WEB_ADMIN,
  production: false,
  version: VERSION,

  //   defaultEndpoint: 'https://192.168.16.10:5001', // Javi
  //   defaultEndpoint: 'https://localhost:5001', // Javi
  // defaultEndpoint: 'https://192.168.91.234:5001', // Gilbert
  // defaultEndpoint: 'https://192.168.16.24:5001', // Alfredo
  // defaultEndpoint: 'https://192.168.90.80:5001', // Alberto
  // defaultEndpoint: 'https://192.168.16.13:5001', // Alberto ofi
  // defaultEndpoint: 'https://192.168.91.77:5001', // David

  // defaultEndpoint: 'https://dev-3-api.iv.solutions',
  // https://192.168.91.30:5001 Albert

  envName: DevelopEnvironment.dev,
  defaultEndpoint: 'https://dev-2-api.iv.solutions',
  devEndpoint: 'https://dev-migration.iv.solutions',
  qaEndpoint: 'https://qa-migration.iv.solutions',
  prodEndpoint: 'https://api.iv.solutions',

  mapbox: {
    accessToken: 'pk.eyJ1IjoiaW5zdGl0dXRvIiwiYSI6ImNrN2xwd3hxdDAyYTkzZXAzdXJvaDd5aTQifQ.ep-LJ8wr0Jm3_nWlWf7NUw',
  },
  googleMapsApiKey: 'AIzaSyBPvVFZ8zWT8uOiYlPUb3zcgGD0oods9dc',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
